import styled from 'styled-components';
// import ImgLogo from "./Images/seta_baixo.png";

// export const BackgroundHeader = styled.header`
//   background-color: ${({ theme }) => theme.color.black};
//   background-color: ${({ theme }) => theme.color.primary};
//   position: fixed;
//   width: 100%;
//   z-index: 2;
// `;

export const BackgroundHeader = styled.header`
    background-color: ${({ theme }) => theme.color.primary};
    position: fixed;
    width: 100%;
    z-index: 102;
    
    @media (max-width: 1040px) {
        display: none;
    }
`;

export const OutHeader = styled.div`
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    height: 95px;
    align-items: center;

    @media (max-width: 1040px) {
        display: none;
    }
`;

export const TitleHeader = styled.div`
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 1170px) {
        font-size: 15px;
    }
`;

export const OutMenuMobile = styled.div`
    display: none;
    cursor: pointer;

    @media (max-width: 1040px) {
        display: flex;
        position: absolute;
        top: 30px;
        left: 30px;
    }    
    
    @media (max-width: 500px) {
        top: 10px;
    }
`;

export const ButtonMenuMobile = styled.div`
    cursor: pointer;
`;

export const RiskMenuMobile = styled.div`
    width: 40px;
    height: 6px;
    background-color: #fff;
    margin-top: 8px;

    @media (max-width: 500px) {
        width: 35px;
        height: 4px;
        background-color: #fff;
        margin-top: 7px;
    }
`;

export const TextMenuMobile = styled.div`
    font-weight: 400;
    font-size: 30px;
    text-transform: uppercase;
    margin-left: 20px;
    margin-top: 3px;

    @media (max-width: 500px) {
        font-size: 23px;
        margin-left: 10px;
        margin-top: 3px;
    }
`;


export const BgMenuMobile = styled.div`
    background-color: #010623;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
`;

export const OutMenuMobile2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const OutLogoMobile = styled.div`
    width: 130px;
    margin-top: 18px;
`;

export const TextXMobile = styled.div`
    font-weight: bold;
    position: absolute;
    right: 35px;
    font-size: 30px;
    top: 15px;
    cursor: pointer;
`;

export const ContentMenuMobile = styled.div`
    width: 90%;
`;

export const TextMenuMobile1 = styled.div`
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid;
    text-align: center;
    padding-bottom: 8px;
    padding-top: 8px;
`;


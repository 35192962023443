import React, { useEffect, useState } from 'react';

import { Header, ScheduleInside, App, Calls, Follow, Footer } from '../../Componentes';

function Agenda(props: any) {
  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1))?.scrollIntoView({ behavior: 'smooth' });
    }
    // }, []);
  }, [props.history.location.hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <link rel='stylesheet' href='fontes/stylesheet.css' />
      <Header />
      <ScheduleInside />
      <App />
      <Calls />
      <Follow /> 
      <Footer />
    </>
  );
}

export default Agenda;

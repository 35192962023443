import React, { useEffect, useState } from "react";
import * as S from "./estilos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BgDiscoResp from "../../Images/BgDiscoResp.jpg";
import ImgBgContentDisco from "../../Images/BgDisco.jpg";
import ImgDisco from "../../Images/ImgDisco.jpg";
import IconSpot from "../../Images/IconSpot.png";
import IconDeezer from "../../Images/IconDeezer.png";
import IconDisco3 from "../../Images/IconDisco3.png";
import IconDisco4 from "../../Images/IconDisco4.png";
import IconDisco5 from "../../Images/IconDisco5.png";
import IconDisco6 from "../../Images/IconDisco6.png";
import IconDisco7 from "../../Images/IconDisco7.png";
import useData from "../../Utils/useData";

const DiscoInside = () => {
  const [data, setData] = useState<any>([]);
  const loadData = () => {
    useData()
      .getData("discografia.php")
      .then((response: any) => {
        setData(response);
        console.log(data.c_link_tidal);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <S.OutBgResp>
        <img src={BgDiscoResp} />
      </S.OutBgResp>

      <S.BgContentDisco>
        <img src={ImgBgContentDisco} />
      </S.BgContentDisco>

      <S.OutContentDisco>
        <S.TextProduction1>Discografia</S.TextProduction1>
        <S.OutIframeDisco>
          <Slider className="SliderDisco" {...settings}>
            {data &&
              data.map((data: any, index: React.Key | null | undefined) => (
                <S.ItemDisco key={index}>
                  <S.TopDisco>
                    <S.TextDisco1>{data.c_ano}</S.TextDisco1>
                    <S.RiscoDisco1 />
                    <S.TextDisco2>{data.c_titulo}</S.TextDisco2>
                  </S.TopDisco>
                  <S.RiscoDisco2 />
                  <S.LeftItem>
                    <iframe
                      src={"https://open.spotify.com/embed/album/" + data.c_album + "?utm_source=generator"}
                      width="100%"
                      className="IframeSpot"
                      frameBorder="0"
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      loading="lazy"></iframe>
                  </S.LeftItem>
                  <S.CenterItem>
                    <img
                      src={
                        "https://daniel.art.br/upload/discografia/" + data.id_tb_discografia + "." + data.c_extensao1
                      }
                    />
                  </S.CenterItem>

                  <S.RightItem>
                    {data.c_link_spotify !== "" && data.c_link_spotify !== null && (
                      <S.OutImgRightItem style={{marginTop: "0px"}} onClick={() => window.open(data.c_link_spotify)}>
                        <img src={IconSpot} />
                      </S.OutImgRightItem>
                    )}

                    {data.c_link_deezer !== "" && data.c_link_deezer !== null && (
                      <S.OutImgRightItem onClick={() => window.open(data.c_link_deezer)}>
                        <img src={IconDeezer} />
                      </S.OutImgRightItem>
                    )}

                    {data.c_link_apple_music !== "" && data.c_link_apple_music !== null && (
                      <S.OutImgRightItem onClick={() => window.open(data.c_link_apple_music)}>
                        <img src={IconDisco3} />
                      </S.OutImgRightItem>
                    )}
                    {data.c_link_youtube !== "" && data.c_link_youtube !== null && (
                      <S.OutImgRightItem onClick={() => window.open(data.c_link_youtube)}>
                        <img src={IconDisco4} />
                      </S.OutImgRightItem>
                    )}
                    {data.c_link_tidal !== "" && data.c_link_tidal !== null && (
                      <S.OutImgRightItem onClick={() => window.open(data.c_link_tidal)}>
                        <img src={IconDisco5} />
                      </S.OutImgRightItem>
                    )}                    
                    {data.c_link_google_play !== "" && data.c_link_google_play !== null && (
                      <S.OutImgRightItem onClick={() => window.open(data.c_link_google_play)}>
                        <img src={IconDisco6} />
                      </S.OutImgRightItem>
                    )}                    
                    {data.c_link_itunes_store !== "" && data.c_link_itunes_store !== null && (
                      <S.OutImgRightItem onClick={() => window.open(data.c_link_itunes_store)}>
                        <img src={IconDisco7} />
                      </S.OutImgRightItem>
                    )}
                  </S.RightItem>
                  <S.RiscoDisco3 />
                </S.ItemDisco>
              ))}
          </Slider>
        </S.OutIframeDisco>
      </S.OutContentDisco>
    </>
  );
};

export default DiscoInside;

import React from 'react';
import * as S from './estilos';
import { useHistory, withRouter } from 'react-router-dom';

// import { Schedule } from '..';
// IconDownload
import BgNewsResp from '../../Images/BgNewsResp.jpg';
import ImgVideo from '../../Images/ImgVideo.jpg';
import IconDownload from '../../Images/IconDownload.png';

const NewsInside = () => {
  const history = useHistory();

  const MandaNews = () => {
    history.push('/News');
  }; 

  return (
    <>  
      <S.OutBgResp>
        <img src={BgNewsResp} />
      </S.OutBgResp>

      <S.BgNews />
      <S.OutNews>
          <S.TextFas1>News</S.TextFas1>
          <S.TextNews1>Notícia</S.TextNews1>
          <S.ContentNews>
            <S.TopInternalNews>
              <S.DataNews>28 . MAR . 23</S.DataNews>
              <S.ButtonVoltar onClick={() => MandaNews()}>Voltar</S.ButtonVoltar>
            </S.TopInternalNews>
            
            <S.TitleNews>A 2ª EDIÇÃO DO NAVIO MAIS ROMÂNTICO DO BRASIL</S.TitleNews>
            <img src={ImgVideo} />
            <S.TextNews2>
                UM MAR DE AMOR
                <br></br><br></br>
                Vamos viver a 2ª edição do Navio que traz um mar de amor contando com a beleza surreal do oceano de cenário! Emoções, sentimentos e experiências únicas estão sendo preparadas com muito carinho para vocês! É mais do que paixão, é mais do que prazer, amor que não tem fim! 
                <br></br><br></br>
                Surpreenda-se ao embarcar no espetáculo mais apaixonante, animado, e único de música, a bordo de um transatlântico exuberante sob o comando de Daniel e artistas convidados incríveis! 
                <br></br><br></br>
                Navegaremos rumo ao sol para viver a entrega de um artista raíz a bordo desse sonho que é a experiência em um cruzeiro temático, o trem vai ficar bom demais! 

                <br></br><br></br>
                Vendas: https://naviodaniel.com.br/
                Informações: (11) 3624-9007
            </S.TextNews2>
          </S.ContentNews>
        </S.OutNews>
    </>
  );
};

export default NewsInside;

import React, { useEffect, useState } from 'react';
import * as S from './estilos';

// import { Schedule } from '..';
// IconDownload
import BgImprensaResp from '../../Images/BgImprensaResp.jpg';
import IconDownload from '../../Images/IconDownload.png';
import ImgBgImprensa from '../../Images/BgImprensa.jpg';
import useData from '../../Utils/useData';

const PressInside = () => {
  const [data, setData] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  const loadData = () => {
    useData()
      .getData('imprensa1.php')
      .then((response: any) => {
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });

    useData()
      .getData('imprensa2.php')
      .then((response: any) => {
        setData2(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <S.OutBgResp>
        <img src={BgImprensaResp} />
      </S.OutBgResp>

      <S.BgPress>
        <img src={ImgBgImprensa} />
      </S.BgPress>

        <S.OutPress>
          <S.TextPress3>Imprensa</S.TextPress3>
          <S.LeftPress>
            <S.TextPress1>Material gráfico</S.TextPress1>
            <S.ContentLeftPress>
              {data &&
                data.map((data: any, index: React.Key | null | undefined) => (
                  <S.ItemPress
                    key={index}
                    onClick={() => {
                      window.open(
                        'https://daniel.art.br/upload/imprensa/' + data.id_tb_imprensa + '.' + data.c_arquivo,
                      );
                    }}>
                    <S.TextPress2>{data.c_titulo}</S.TextPress2>
                    <S.ButtonDownload>
                      <S.OutIconDownload>
                        <img src={IconDownload} />
                      </S.OutIconDownload>
                      <S.TextButtonDownload>Download</S.TextButtonDownload>
                    </S.ButtonDownload>
                  </S.ItemPress>
                ))}
            </S.ContentLeftPress>
          </S.LeftPress>
          <S.RiskPress />
          <S.LeftPress>
            <S.TextPress1>Áudios</S.TextPress1>
            <S.ContentLeftPress>
              {data2 &&
                data2.map((data2: any, index2: React.Key | null | undefined) => (
                  <S.ItemPress 
                  key={index2}
                  onClick={() => {
                    window.open(
                      'https://daniel.art.br/upload/imprensa/' + data2.id_tb_imprensa + '.' + data2.c_arquivo,
                    );
                  }}
                  >
                    <S.TextPress2>{data.c_titulo}</S.TextPress2>
                    <S.ButtonDownload>
                      <S.OutIconDownload>
                        <img src={IconDownload} />
                      </S.OutIconDownload>
                      <S.TextButtonDownload>Download</S.TextButtonDownload>
                    </S.ButtonDownload>
                  </S.ItemPress>
                ))}
            </S.ContentLeftPress>
          </S.LeftPress>
        </S.OutPress>
    </>
  );
};

export default PressInside;

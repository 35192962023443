import React, { useEffect, useRef, useState } from 'react';
import * as S from './estilos';
import ImageGallery from 'react-image-gallery';
// import { Schedule } from '..';
// IconDownload
import BgVideosResp from '../../Images/BgVideosResp.jpg';
import IconMaisVideos from '../../Images/IconMaisVideos.png';
import ImgBgVideos from '../../Images/BgFotos.jpg';
import useData from '../../Utils/useData';
import ModalVideo from 'react-modal-video';

const GalleryInside = () => {
  const [data, setData] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [imageVisible, setImageVisible] = useState<any>(false);
  const isVisibleNext = useRef<any>(true);

  const category = useRef<any>('');
  const page = useRef<any>(1);

  const loadData = async () => {
    await fetch(
      `https://api.flickr.com/services/rest/?method=flickr.photosets.getList&api_key=d0f8f86d1d4076fa5261edaacefbb387&user_id=61742163@N06&format=json&nojsoncallback=1&page=${page.current}&per_page=8`,
      {
        method: 'GET',
        redirect: 'follow',
        headers: {},
      },
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // resolve(responseJson);
        // console.log(responseJson.photosets.photoset);
        if (page.current == 1) {
          setData(responseJson.photosets.photoset);
        } else {
          setData((data: any) => [...data, ...responseJson.photosets.photoset]);
        }
      })
      .catch((error) => {
        // reject(error);
      });
  };

  const loadGaleria = async (id: any) => {
    await fetch(
      `https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=d0f8f86d1d4076fa5261edaacefbb387&photoset_id=${id}&user_id=61742163%40N06&format=json&nojsoncallback=1`,
      {
        method: 'GET',
        redirect: 'follow',
        headers: {},
      },
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // resolve(responseJson);
        // console.log(responseJson.photoset);
        const myData = responseJson.photoset.photo;

        const myArray: { original: string; thumbnail: string }[] = [];

        myData.map((data: any, index: React.Key | null | undefined) => {
          // console.log(`https://farm${data.farm}.staticflickr.com/${data.server}/${data.id}_${data.secret}_b.jpg`);
          myArray.push({
            original: `https://farm${data.farm}.staticflickr.com/${data.server}/${data.id}_${data.secret}_b.jpg`,
            thumbnail: `https://farm${data.farm}.staticflickr.com/${data.server}/${data.id}_${data.secret}.jpg`,
          });
        });

        setImages(myArray);
        setImageVisible(true);

        // console.log(myArray);

        // if (page.current == 1) {
        //   setData(responseJson.photosets.photoset);
        // } else {
        //   setData((data: any) => [...data, ...responseJson.photosets.photoset]);
        // }
      })
      .catch((error) => {
        // reject(error);
      });
  };

  useEffect(() => {
    category.current = '';
    loadData();
  }, []);

  return (
    <>
      <S.OutBgResp>
        <img src={BgVideosResp} />
      </S.OutBgResp>
      <S.BgVideo>
        <img src={ImgBgVideos} />
      </S.BgVideo>
      <S.OutVideo>
        <S.TextProduction1>Fotos</S.TextProduction1>
        <S.ContentVideo>
          {data &&
            data.map((data: any, index: React.Key | null | undefined) => (
              <S.OutItemVideo
              key={index}
              >
                <S.ItemVideo       
                  onClick={() => {
                    loadGaleria(data.id);
                  }}
                  style={{
                    backgroundImage:
                      "url('https://farm" +
                      data.farm +
                      '.staticflickr.com/' +
                      data.server +
                      '/' +
                      data.primary +
                      '_' +
                      data.secret +
                      '.jpg' +
                      "')",
                  }}       
                  >
                    
                  <S.OutImgVideo>
                    {/* <img
                      style={{ marginBottom: '-7px' }}
                      src={`https://farm${data.farm}.staticflickr.com/${data.server}/${data.primary}_${data.secret}.jpg`}
                    /> */}
                    {/* <img style={{ marginBottom: '-7px' }} src={data.primary_photo_extras.url_s} /> */}
                  </S.OutImgVideo>
                  <S.RiskImgVideo />
                </S.ItemVideo>
                <S.LowItemVideo>
                    <S.RiskItemVideo />
                    <S.TextItemVideo>{data.title._content}</S.TextItemVideo>
                  </S.LowItemVideo>
              </S.OutItemVideo>

            ))}
        </S.ContentVideo>
        <S.LowVideo>
          <S.RiskLowVideo />

          {isVisibleNext.current && (
            <S.OutMoreVideos
              onClick={() => {
                page.current = page.current + 1;
                loadData();
              }}>
              <S.TextMoreVideos>Mais Fotos</S.TextMoreVideos>
              <S.OutIconVideos>
                <img src={IconMaisVideos} />
              </S.OutIconVideos>
            </S.OutMoreVideos>
          )}
        </S.LowVideo>
      </S.OutVideo>
      {imageVisible && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10000,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <S.XGallery onClick={() => setImageVisible(false)}>X</S.XGallery>
          <ImageGallery items={images} showThumbnails={false} />
        </div>
      )}
    </>
  );
};

export default GalleryInside;

import styled from 'styled-components';
import ImgBgNews from '../../Images/BgNews.jpg';

export const BgNews = styled.section`
    padding-top: 90px;

    @media (max-width: 1040px) {
    padding-top: 0px;
    }

    @media (max-width: 950px) {
    display: none;
    }
`;

export const OutBgResp = styled.div`
    display: none;
    @media (max-width: 950px) {
        display: block;
    }
`;

export const TextFas1 = styled.div`
    font-size: 75px;
    font-weight: 800;
    text-transform: uppercase;
    padding-bottom: 7vw;
    padding-top: 9vw;
    display: none;

    @media (max-width: 950px) {
        display: block;
        font-size: 40px;
        padding-bottom: 30px;
        padding-top: 0px;
        padding-top: 0px;
        text-align: center;
    } 

    @media (max-width: 550px) {
        font-size: 30px;
    } 
`;

export const OutNews = styled.div`
    width: 83%;
    margin: 0 auto;
    margin-top: -36vw;
    padding-bottom: 1vw;

    @media (max-width: 950px) {
        margin-top: -24vw; 
    } 
`;

export const OutNews2 = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-bottom: 1vw;

    img {
        width: auto;
        margin-top: 35px;
        margin-bottom: 35px;
    }

    @media (max-width: 950px) {
   
    } 

    @media (max-width: 550px) {
        img {
            max-width: 100%;
        }
    } 
`;

export const TextNews1 = styled.div`
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
`;

export const ContentNews = styled.div`

`;

export const ItemNews = styled.div`
    display: flex;
    border-bottom: 2px solid #10204d;
    padding-bottom: 40px;
    margin-top: 40px;
    cursor: pointer;
    position: relative;

    @media (max-width: 1320px) {
        padding-bottom: 25px;
        margin-top: 25px;
    }    
    
    @media (max-width: 850px) {
        flex-direction: column;
        padding-bottom: 20px;
        margin-top: 20px;
    }
`;

export const TextNews2 = styled.div`
    font-size: 22px;
    font-weight: bold;
    padding-right: 15px;

    @media (max-width: 950px) {
        width: 96px;
        font-size: 20px;
    } 

    @media (max-width: 850px) {
       width: 100%;
    }

    @media (max-width: 450px) {
        font-size: 18px;
    }
`;

export const TextNews3 = styled.div`
    font-size: 22px;
    font-weight: 500;
    color: #cedbed;

    @media (max-width: 950px) {
        font-size: 20px;
        width: 80%;
    } 

    @media (max-width: 850px) {
       width: 100%;
    }   
    
    @media (max-width: 450px) {
        font-size: 18px;
    }
`;



export const ButtonSchedule2 = styled.div`
  width: 350px;
  margin: 0 auto;
  border: 3px solid #1c2f65;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  margin-top: -3px;
  border-bottom-left-radius: 33px;
  border-bottom-right-radius: 33px;
  background-color: #07194b;
  cursor: pointer;

  @media (max-width: 540px) {
    width: 70%;
  }
`;

export const TextButtonSchedule2 = styled.div`
  text-transform: uppercase;
  color: #5990ed;
  font-size: 18px;
  font-weight: 500;
`;

export const OutImgIconSchedule3 = styled.div`
   margin-top: 10px;
   margin-left: 15px;
`;



export const ContentNews2 = styled.div`
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TopInternalNews = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const DataNews = styled.div`
    font-weight: bold;
    color: #fff;
    font-size: 25px;
    width: auto;

    @media (max-width: 550px) {
        font-size: 23px;
    } 
`;

export const ButtonVoltar = styled.div`
    font-weight: bold;
    text-transform: uppercase;
    font-size: 21px;
    text-decoration: underline;
    cursor: pointer;
`;

export const TitleNews = styled.div`
    font-weight: bold;
    font-size: 24px;
    border-bottom: 2px solid #446fe7;
    padding-bottom: 21px;
    margin-top: 20px;
    width: 100%;

    @media (max-width: 550px) {
        font-size: 19px;
    } 
`;

export const OutImageNews = styled.div`
    margin: 0 auto;
    margin-top: 35px;
    margin-bottom: 35px;
`;

export const TextNewsInternal = styled.div`
    font-size: 21px;
    font-weight: 400;

    @media (max-width: 550px) {
        font-size: 18px; 
    } 
`;


import React from "react";
import { HashRouter as Router1, Route, Switch } from "react-router-dom";
import Home from "../Pages/Home";
import Agenda from "../Pages/Agenda";
import Discografia from "../Pages/Discografia";
import Imprensa from "../Pages/Imprensa";
import Producao from "../Pages/Producao";
import Contato from "../Pages/Contato";
import Videos from "../Pages/Videos";
import Galeria from "../Pages/Galeria";
import News from "../Pages/News";
import Aplicativo from "../Pages/Aplicativo";
import NewsInterna from "../Pages/NewsInterna";
import Fas from "../Pages/Fas";
import { ThemeProvider } from "styled-components";
import theme from "../Styles/theme";

const Router = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router1>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/Agenda" exact component={Agenda} />
            <Route path="/Discografia" exact component={Discografia} />
            <Route path="/Fas" exact component={Fas} />
            <Route path="/Imprensa" exact component={Imprensa} />
            <Route path="/News" exact component={News} />
            <Route path="/aplicativo" exact component={Aplicativo} />
            <Route path="/NewsInterna" exact component={NewsInterna} />
            <Route path="/Producao" exact component={Producao} />
            <Route path="/Contato" exact component={Contato} />
            <Route path="/Videos" exact component={Videos} />
            <Route path="/Galeria" exact component={Galeria} />
            {/* Outras rotas */}
          </Switch>
        </Router1>
      </ThemeProvider>
    </>
  );
};

export default Router;

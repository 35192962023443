import styled from 'styled-components';
import ImgBgImprensa from '../../Images/BgImprensa.jpg';

export const BgPress = styled.section`
  padding-top: 90px;

    @media (max-width: 1040px) {
    padding-top: 0px;
    }

    @media (max-width: 950px) {
    display: none;
    }
`;

export const OutBgResp = styled.div`
    display: none;
    @media (max-width: 950px) {
        display: block;
    }
`;

export const OutPress = styled.div`
    width: 83%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: -37vw;
    padding-bottom: 2vw;
    
    @media (max-width: 950px) {
        flex-direction: column;
        padding-top: 0vw;
        margin-top: -26vw;
    }
`;

export const TextPress3 = styled.div`
    font-size: 75px;
    font-weight: 800;
    text-transform: uppercase;
    padding-bottom: 7vw;
    padding-top: 9vw;
    display: none;

    @media (max-width: 950px) {
        display: block;
        font-size: 40px;
        padding-bottom: 30px;
        padding-top: 0px;
        padding-top: 0px;
        text-align: center;
    } 

    @media (max-width: 550px) {
        font-size: 30px;
    } 
`;


export const RiskPress = styled.div`
    width: 2px;
    background-color: #3150a6;
`;

export const LeftPress = styled.div`
    width: 48%;

    @media (max-width: 950px) {
        width: 100%;
        margin-top: 15px;
    } 
`;

export const TextPress1 = styled.div`
    font-weight: bold;
    font-size: 22px;
    text-transform: uppercase;
`;

export const ContentLeftPress = styled.div`

`;

export const ItemPress = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #12214f;
    padding-bottom: 30px;
    margin-top: 30px;

    @media (max-width: 1560px) {
        padding-bottom: 20px;
        margin-top: 20px;
    }   
    
    @media (max-width: 1250px) {
        padding-bottom: 15px;
        margin-top: 15px;
    }
`;

export const TextPress2 = styled.div`
    font-size: 22px;
    font-weight: 500;
    color: #cedbed;

    @media (max-width: 1560px) {
        font-size: 20px;
        width: 71%;
    }

    @media (max-width: 1250px) {
        font-size: 17px;
        width: 70%;
    }

    @media (max-width: 440px) {
        font-size: 15px;
        width: 60%;
        line-height: 21px;
    }
`;

export const ButtonDownload = styled.div`
    display: flex;
    width: 160px;
    /* border: 3px solid #3150a6; */
    align-items: center;
    justify-content: center;
    height: 50px;
    border-radius: 40px;
    cursor: pointer;
    background-color: #fff;

    @media (max-width: 1560px) {
        width: 145px;
    }

    @media (max-width: 1250px) {
        width: 130px;
        height: 40px;
    }

    @media (max-width: 440px) {
        width: 110px;
        height: 35px;
        border: 2px solid #3150a6;
    }
`;

export const OutIconDownload = styled.div`
    padding-right: 6px;
    padding-top: 5px;

    @media (max-width: 1250px) {
        width: 19px;
    }

    @media (max-width: 440px) {
        width: 17px;
    }
`;

export const TextButtonDownload = styled.div`
    font-size: 18px;
    font-weight: 800;
    color: #3150a6;

    @media (max-width: 1250px) {
        font-size: 16px;
    }

    @media (max-width: 440px) {
        font-size: 13px;
    }
`;

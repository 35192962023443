/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import * as S from './estilos';

import IconLogo from '../../Images/IconLogo.png';

function Header(props: any) {
  const history = useHistory();
  const [menu, setMenu] = useState(0);

  const MandaHome = () => {
    history.push('/');
    setMenu(0);
  };  
  
  const MandaAgenda = () => {
    history.push('/Agenda');
    setMenu(0);
  };  
  
  const MandaDiscografia = () => {
    history.push('/Discografia');
    setMenu(0);
  };  
  
  const MandaFotos = () => {
    history.push('/Galeria');
    setMenu(0);
  };  
  
  const MandaVideos = () => {
    history.push('/Videos');
    setMenu(0);
  };  
  
  const MandaFas = () => {
    history.push('/Fas');
    setMenu(0);
  };  
  
  const MandaImprensa = () => {
    history.push('/Imprensa');
    setMenu(0);
  };  
  
  const MandaNews = () => {
    history.push('/News');
    setMenu(0);
  }; 
  
  const MandaRelease = () => {
    history.push('/Release');
    setMenu(0);
  };  
  
  const MandaProducao = () => {
    history.push('/Producao');
    setMenu(0);
  };  
  
  const MandaCorporativo = () => {
    window.open('https://danielcorp.com.br/', '_blank');
    setMenu(0);
  };  
  
  const MandaContato = () => {
    history.push('/Contato');
    setMenu(0);
  };

  function MeuComponente() {
    if (window.location.href.includes('#DanielApp')) {
      // console.log("sim");
      history.push('/Aplicativo');
      // alert(window.location.href);
    } else {
      // console.log("nao");
    }
  }


  useEffect(() => {
    MeuComponente();
    // history.push('/#DanielApp');
  }, []);

  // useEffect(() => {
  //   const hash = props.history.location.hash;
  //   if (hash && document.getElementById(hash.substr(1))) {
  //     // Check if there is a hash and if an element with that id exists
  //     document.getElementById(hash.substr(1))?.scrollIntoView({ behavior: 'smooth' });
  //   }
  //   // }, []);
  // }, [props.history.location.hash]);

  return (
     <>
      <S.BackgroundHeader>
        <S.OutHeader>
          <S.TitleHeader onClick={() => MandaHome()}>Home</S.TitleHeader>
          <S.TitleHeader onClick={() => MandaAgenda()}>Agenda</S.TitleHeader>
          <S.TitleHeader onClick={() => MandaDiscografia()}>Discografia</S.TitleHeader>
          <S.TitleHeader onClick={() => MandaFotos()}>Fotos</S.TitleHeader>
          <S.TitleHeader onClick={() => MandaVideos()}>Vídeos</S.TitleHeader>
          <S.TitleHeader onClick={() => MandaFas()}>Fãs</S.TitleHeader>
          <S.TitleHeader onClick={() => MandaImprensa()}>Imprensa</S.TitleHeader>
          <S.TitleHeader onClick={() => MandaNews()}>News</S.TitleHeader>
          {/* <S.TitleHeader onClick={() => MandaRelease()}>Release</S.TitleHeader> */}
          <S.TitleHeader onClick={() => MandaProducao()}>Produção</S.TitleHeader>
          <S.TitleHeader onClick={() => MandaCorporativo()}>Corporativo</S.TitleHeader>
          <S.TitleHeader onClick={() => MandaContato()}>Contato</S.TitleHeader>
        </S.OutHeader>
      </S.BackgroundHeader>

        <S.OutMenuMobile onClick={() => setMenu(1)}>
          <S.ButtonMenuMobile>
            <S.RiskMenuMobile />
            <S.RiskMenuMobile />
            <S.RiskMenuMobile />
          </S.ButtonMenuMobile>
          <S.TextMenuMobile>Menu</S.TextMenuMobile>
        </S.OutMenuMobile>

      {menu == 1 ? (
          <S.BgMenuMobile>
            <S.OutMenuMobile2>
              <S.OutLogoMobile><img src ={IconLogo} /></S.OutLogoMobile>
              <S.TextXMobile onClick={() => setMenu(0)}>X</S.TextXMobile>
              <S.ContentMenuMobile>
                <S.TextMenuMobile1 onClick={() => MandaHome()}>Home</S.TextMenuMobile1>
                <S.TextMenuMobile1 onClick={() => MandaAgenda()}>Agenda</S.TextMenuMobile1>
                <S.TextMenuMobile1 onClick={() => MandaDiscografia()}>Discografia</S.TextMenuMobile1>
                <S.TextMenuMobile1 onClick={() => MandaFotos()}>Fotos</S.TextMenuMobile1>
                <S.TextMenuMobile1 onClick={() => MandaVideos()}>Vídeos</S.TextMenuMobile1>
                <S.TextMenuMobile1 onClick={() => MandaFas()}>Fãs</S.TextMenuMobile1>
                <S.TextMenuMobile1 onClick={() => MandaNews()}>News</S.TextMenuMobile1>
                {/* <S.TextMenuMobile1 onClick={() => MandaRelease()}>Release</S.TextMenuMobile1> */}
                <S.TextMenuMobile1 onClick={() => MandaProducao()}>Produção</S.TextMenuMobile1>
                <S.TextMenuMobile1 onClick={() => MandaCorporativo()}>Corporativo</S.TextMenuMobile1>
                <S.TextMenuMobile1 onClick={() => MandaContato()}>Contato</S.TextMenuMobile1>
              </S.ContentMenuMobile>
            </S.OutMenuMobile2>
          </S.BgMenuMobile>
        ) : (
         <></>
       )}
      </>
  );
}

export default withRouter(Header);

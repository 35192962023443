/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';

import IconApp1 from '../../Images/IconApp1.png';
import IconApp2 from '../../Images/IconApp2.png';
import BgAppResp from '../../Images/BgAppResp.jpg';

import * as S from './estilos';

function App(props: any) {

  const MandaAndroid = () => {
    window.open('https://play.google.com/store/apps/details?id=com.cantordaniel&pli=1', '_blank');
  };  
  
  const MandaiPhone = () => {
    window.open('https://apps.apple.com/br/app/danielapp/id1462809293', '_blank');
  };  

  const myRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <>
    <div ref={myRef} className='DanielApp2' id="DanielApp"></div>
      <S.BgApp>
        <S.OutApp>
          <S.LeftApp>
            <S.TextApp1>Daniel App</S.TextApp1>
            <S.ContentApp>
              <S.ItemApp onClick={() => MandaAndroid()}>
                <S.OutImgApp>
                  <img src={IconApp1} />
                </S.OutImgApp>
                <S.ButtonApp>
                  <S.TextButtonApp>Baixe para <span>Android</span></S.TextButtonApp>
                </S.ButtonApp>
              </S.ItemApp>          
              <S.ItemApp onClick={() => MandaiPhone()}>
                <S.OutImgApp>
                  <img src={IconApp2} />
                </S.OutImgApp>
                <S.ButtonApp>
                  <S.TextButtonApp>Baixe para <span>iPhone</span></S.TextButtonApp>
                </S.ButtonApp>
              </S.ItemApp>
            </S.ContentApp>
          </S.LeftApp>
        </S.OutApp>
      </S.BgApp>

      <S.OutAppResp>
        <img src={BgAppResp} />
        <S.OutLinkApp1 onClick={() => MandaAndroid()} />
        <S.OutLinkApp2 onClick={() => MandaiPhone()} />
      </S.OutAppResp>
    </>
  );
}

export default App;

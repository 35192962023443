/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ImgSlider from '../../Images/ImgSlider.jpg';
import ImgSliderResp from '../../Images/ImgSliderResp.jpg';

import * as S from './estilos';
import useData from '../../Utils/useData';

function Content(props: any) {
  const [data, setData] = useState<any>([]);
  const [dataconfig, setDataConfig] = useState<any>([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const loadData = () => {
    useData()
      .getData('banner.php')
      .then((response: any) => {
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });    
      useData()
      .getData('configs.php')
      .then((response2: any) => {
        setDataConfig(response2);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <S.BackgroundContent>
      <S.OutIframe>
        {dataconfig &&
            dataconfig.map((dataconfig: any, index: React.Key | null | undefined) => (
            <iframe
              key={index}
              className='Iframe'
              src={'https://www.youtube.com/embed/' + dataconfig.c_link_youtube}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; 
            gyroscope; picture-in-picture; web-share'
              allowFullScreen />
            ))}

      </S.OutIframe>

      <S.OutCarrousel>
        <Slider className='SliderBanner1' {...settings}>
          {data &&
            data.map((data: any, index: React.Key | null | undefined) => (
              <a
              href={data.c_link}
              target='_blank'
              rel="noreferrer"
              key={index}
              >
                <img             
                  src={'https://daniel.art.br/upload/banner/' + data.id_tb_banner + 'a.' + data.c_extensao1}
                />
              </a>
            ))}
        </Slider>
      </S.OutCarrousel>

      <S.OutCarrouselResp>
        <Slider className='SliderBanner1' {...settings}>
          {data &&
            data.map((data: any, index: React.Key | null | undefined) => (
              <a
              href={data.c_link}
              target='_blank'
              rel="noreferrer"
              key={index}
              >
              <img
                key={index}
                src={'https://daniel.art.br/upload/banner/' + data.id_tb_banner + 'b.' + data.c_extensao2}
              />
               </a>
            ))}
        </Slider>
      </S.OutCarrouselResp>
    </S.BackgroundContent>
  );
}

export default Content;

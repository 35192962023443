import React from 'react';
import * as S from './estilos';

// import { Schedule } from '..';

import BgFasResp from '../../Images/BgFasResp.jpg';
import ImgBgFas from '../../Images/BgFas.jpg';

const Banner = () => {
  return (
    <>  
      <S.OutBgResp>
        <img src={BgFasResp} />
      </S.OutBgResp>

      <S.BgFas >
        <img src={ImgBgFas} />
      </S.BgFas>
      
      <S.OutFas>
          <S.TextFas1>Central dos fãs</S.TextFas1>
          <S.ContentFas>
            <S.LeftFas>
              <S.TextFas2>Fã Clube</S.TextFas2>
              <S.TextFas3>
                Uma associação ou um grupo de pessoas que interage para expressar sua admiração e carinho por uma pessoa conhecida na mídia ou mundo artístico.
              </S.TextFas3>             
               <S.TextFas3>
                O Cantor Daniel possui vários grupos que o prestigiam em shows, programas de televisão, e possuem redes sociais para estreitar a comunicação entre seus membros. Ao Curtir as Redes Oficiais do cantor Daniel que você encontra em nosso site, é possível ficar por dentro de tudo o que acontece na vida do artista e fazer amigos.
              </S.TextFas3>
            </S.LeftFas>
            <S.RiskFas /> 
            <S.RightFas>
              <S.TextFas2>Importante</S.TextFas2>
              <S.TextFas3>
                Se você deseja fazer parte da Central de fãs, baixe o Daniel APP e faça o seu cadastro. Para participar de acesso exclusivo ao Camarim, Promoções e receber o cartão virtual de aniversário (com mensagem exclusiva do Daniel) mantenha seu cadastro sempre atualizado.
              </S.TextFas3>              
              <S.TextFas3>
                Entre em contato pelo email          
              </S.TextFas3>
              <S.TextFas6 href="mailto:atendimento@danielpromocoes.com.br">atendimento@danielpromocoes.com.br</S.TextFas6>
              <S.ContentBlueFas>
                <S.TextFas2>Central de Atendimento</S.TextFas2>
                <S.TextFas7>Marlene Simões</S.TextFas7>
                <S.TextFas4>+55 14 3653-9897</S.TextFas4>
                <S.TextFas5 href="mailto:atendimento@danielpromocoes.com.br">atendimento@danielpromocoes.com.br</S.TextFas5>
              </S.ContentBlueFas>
            </S.RightFas>
          </S.ContentFas>
        </S.OutFas>
    </>
  );
};

export default Banner;

import React, { useEffect, useRef, useState } from "react";
import * as S from "./estilos";

// import { Schedule } from '..';
// IconDownload
import BgVideosResp from "../../Images/BgVideosResp.jpg";
import ImgBgVideos from "../../Images/BgVideos.jpg";
import ImgVideo from "../../Images/ImgVideo.jpg";
import IconMaisVideos from "../../Images/IconMaisVideos.png";
import useData from "../../Utils/useData";
import ModalVideo from "react-modal-video";

const VideosInside = () => {
  const [data, setData] = useState<any>([]);
  const [url, setUrl] = useState("");
  const isVisibleNext = useRef<any>(true);
  const category = useRef<any>("");
  const page = useRef<any>(1);

  const loadData = () => {
    useData()
      .getData(`videos.php?page=${page.current}&category=${category.current}`)
      .then((response: any) => {
        console.log(response);

        if (response.length < 8) {
          isVisibleNext.current = false;
        }
        if (page.current == 1) {
          setData(response);
        } else {
          setData((data: any) => [...data, ...response]);
        }
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    category.current = "";
    loadData();
  }, []);

  return (
    <>
      <S.OutBgResp>
        <img src={BgVideosResp} />
      </S.OutBgResp>

      <S.BgVideo>
        <img src={ImgBgVideos} />
      </S.BgVideo>

      <S.OutVideo>
        <S.TextProduction1>Vídeos</S.TextProduction1>
        <S.TopVideo>
          <S.OutItemsTopVideo>
            <S.ItemTopVideo
              onClick={() => {
                category.current = "";
                setData([]);
                isVisibleNext.current = true;
                page.current = 1;
                loadData();
              }}>
              <S.TextTopVideo>Recentes</S.TextTopVideo>
            </S.ItemTopVideo>
            <S.ItemTopVideo
              onClick={() => {
                category.current = "1";
                setData([]);
                isVisibleNext.current = true;
                page.current = 1;
                loadData();
              }}>
              <S.TextTopVideo>Destaques</S.TextTopVideo>
            </S.ItemTopVideo>
            <S.ItemTopVideo
              onClick={() => {
                category.current = "2";
                isVisibleNext.current = true;
                page.current = 1;
                setData([]);
                loadData();
              }}>
              <S.TextTopVideo>Playlists</S.TextTopVideo>
            </S.ItemTopVideo>
          </S.OutItemsTopVideo>
          <S.RiskTopVideo />
        </S.TopVideo>
        <S.ContentVideo>
          {data &&
            data.map((data: any, index: React.Key | null | undefined) => (
              <S.ItemVideo onClick={() => setUrl(data.c_youtube)} key={index}>
                <div>
                  {/* {data.id_tb_tipo} - {data.id_tb_categoria} */}
                </div>
                {data.c_extensao1 === null || data.c_extensao1 === "" ? (
                  <S.OutImgVideo>
                    <img
                      style={{ marginBottom: "-7px" }}
                      src={"https://img.youtube.com/vi/" + data.c_youtube + "/mqdefault.jpg"}
                    />
                  </S.OutImgVideo>
                ) : (
                  <S.OutImgVideo>
                    <img
                      style={{ marginBottom: "-7px" }}
                      src={`https://daniel.art.br/upload/video/${data.id_tb_video}.${data.c_extensao1}`}
                    />
                  </S.OutImgVideo>
                )}

                <S.LowItemVideo>
                  <S.RiskItemVideo />
                  <S.TextItemVideo>{data.c_titulo}</S.TextItemVideo>
                </S.LowItemVideo>
                <S.RiskImgVideo />
              </S.ItemVideo>
            ))}
        </S.ContentVideo>
        <S.LowVideo>
          <S.RiskLowVideo />

          {isVisibleNext.current && (
            <S.OutMoreVideos
              onClick={() => {
                page.current = page.current + 1;
                loadData();
              }}>
              <S.TextMoreVideos>Mais vídeos</S.TextMoreVideos>
              <S.OutIconVideos>
                <img src={IconMaisVideos} />
              </S.OutIconVideos>
            </S.OutMoreVideos>
          )}
        </S.LowVideo>
      </S.OutVideo>

      {url !== "" && (
        <S.OutIframeVideo>
          <S.XIfame onClick={() => setUrl("")}>X</S.XIfame>
          <iframe
            className="IframeVideo"
            src={"https://www.youtube.com/embed/" + url}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
            gyroscope; picture-in-picture; web-share"
            allowFullScreen></iframe>
        </S.OutIframeVideo>
      )}
    </>
  );
};

export default VideosInside;

import styled from 'styled-components';


export const BgSchedule = styled.section`

`;

export const OutSchedule = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: -85px;

  @media (max-width: 540px) {
    width: 90%;
  }
`;

export const TextSchedule1 = styled.div`
  font-weight: 800;
  font-size: 40px;
  text-transform: uppercase;

  @media (max-width: 900px) {
    text-align: center;
  }
`;

export const ContentSchedule = styled.div`
  margin-top: 50px;

  @media (max-width: 950px) {
    margin-top: 0px;
  }
`;

export const TopContentSchedule = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const LeftContentSchedule = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  border-bottom: 2px solid #304fa7;
  margin-bottom: 20px;
  padding-bottom: 23px;

  @media (max-width: 1420px) {
    width: 170px;
  }    
  
  @media (max-width: 700px) {
    width: 135px;
  }  
  
`;

export const CenterContentSchedule = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  border-bottom: 2px solid #304fa7;
  margin-bottom: 20px;
  padding-bottom: 23px;

  @media (max-width: 1420px) {
    width: 63%;
  }  
  
  @media (max-width: 1152px) {
    width: 64%;
  }

  @media (max-width: 900px) {
    width: 61%;
  }
`;

export const OutImgIconSchedule = styled.div`
  width: 35px;
`;

export const TextSchedule2 = styled.div`
  color: ${({ theme }) => theme.color.button};
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 15px;
`;

export const OutItemsSchedule = styled.div`

`;

export const ItemSchedule = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #132451;
  padding-bottom: 50px;
  margin-top: 50px;

  @media (max-width: 1420px) {
    flex-wrap: wrap;
    padding-bottom: 35px;
    margin-top: 35px;
  }  
  
  @media (max-width: 700px) {
    padding-bottom: 20px;
    margin-top: 20px;
  }
`;

export const LeftItemSchedule = styled.div`
  width: 20%;
  display: flex;

  @media (max-width: 1420px) {
    width: 161px;
  }  
  
  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const TextItemSchedule1 = styled.div`
  font-size: 100px;
  font-weight: bold;
  line-height: 100px;
  width: 115px;

  @media (max-width: 1420px) {
    font-size: 77px;
    line-height: 77px;
  }  
  
  @media (max-width: 700px) {
    font-size: 63px;
    line-height: 63px;
    width: 80px;
  }

`;

export const OutTextItemSchedule2 = styled.div`
    margin-left: 17px;

    @media (max-width: 1420px) {
      margin-left: 4px;
      margin-top: 2px;
    }
`;

export const TextItemSchedule2 = styled.div`
  font-size: 50px;
  font-weight: bold;
  color: #446fe7;
  text-transform: uppercase;
  line-height: 63px;
  letter-spacing: -3px;

  @media (max-width: 1420px) {
    font-size: 35px;
    line-height: 43px;
    letter-spacing: 0.05px;
  }

  @media (max-width: 700px) {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const TextItemSchedule3 = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #446fe7;
  line-height: 7px;
  letter-spacing: 2px;

  @media (max-width: 1420px) {
    font-size: 17px;
  } 
  
  @media (max-width: 700px) {
    font-size: 19px;
    line-height: 16px;
  }
`;

export const CenterItemSchedule = styled.div`
  width: 50%;

  @media (max-width: 1420px) {
    width: 64%;
    margin-left: 10px;
  }

  @media (max-width: 900px) {
    width: 61%;
  }  
  
  @media (max-width: 700px) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const TextItemSchedule4 = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;

  @media (max-width: 700px) {
    font-size: 23px;
    line-height: 20px;
  }  
  
  @media (max-width: 540px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const TextItemScheule5 = styled.div`
  font-size: 18px;
  font-weight: 300;

  @media (max-width: 540px) {
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
  }
`;

export const TextItemSchedule6 = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;

  span {
    text-transform: capitalize;
    color: #619cff;
  }

  @media (max-width: 1152px) {
    font-size: 15px;
  }

  @media (max-width: 450px) {
    font-size: 12.5px;
  }
`;

export const ButtonSchedule = styled.div`
  width: 235px;
  height: 55px;
  border: 2px solid #446fe7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;

  :hover {
    cursor: pointer;
    background-color: #446fe7;
  }
  

  @media (max-width: 1152px) {
    width: 100%;
    margin-top: 25px;
  }  
  
  @media (max-width: 540px) {
    margin-top: 13px;
    height: 45px;
  }
`;

export const TextSchedule7 = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const OutImgIconSchedule2 = styled.div`
    width: 30px;
    margin-left: 13px;

    @media (max-width: 900px) {
      margin-top: 4px;
    }
`;

export const ButtonSchedule2 = styled.div`
  width: 350px;
  margin: 0 auto;
  border: 3px solid #1c2f65;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  margin-top: -3px;
  border-bottom-left-radius: 33px;
  border-bottom-right-radius: 33px;
  background-color: #07194b;
  cursor: pointer;

  @media (max-width: 540px) {
    width: 70%;
  }
`;

export const TextButtonSchedule2 = styled.div`
  text-transform: uppercase;
  color: #5990ed;
  font-size: 18px;
  font-weight: 500;
`;

export const OutImgIconSchedule3 = styled.div`
   margin-top: 10px;
   margin-left: 15px;
`;

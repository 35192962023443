import styled from 'styled-components';

export const BgProduction = styled.section`
  padding-top: 90px;

    @media (max-width: 1040px) {
    padding-top: 0px;
    }

    @media (max-width: 950px) {
    display: none;
    }
`;

export const OutBgResp = styled.div`
    display: none;
    @media (max-width: 950px) {
        display: block;
    }
`;

export const TextProduction1 = styled.div`
    font-size: 75px;
    font-weight: 800;
    text-transform: uppercase;
    padding-bottom: 7vw;
    padding-top: 9vw;
    display: none;

    @media (max-width: 950px) {
        display: block;
        font-size: 40px;
        padding-bottom: 30px;
        padding-top: 0px;
        padding-top: 0px;
        text-align: center;
    } 

    @media (max-width: 550px) {
        font-size: 30px;
    } 
`;

export const OutProduction = styled.div`
    width: 83%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: -20vw;
    padding-bottom: 5vw;

    @media (max-width: 950px) {
        flex-direction: column;
        padding-top: 0vw;
        padding-bottom: 0vw;
        margin-top: -18vw;
    } 

    @media (max-width: 450px) {
        width: 85%;
    }
`;

export const LeftProduction = styled.div`
    width: 48%;

    @media (max-width: 950px) {
        width: 100%;
    } 
`;

export const TextProduction2 = styled.div`
    font-weight: 300;
    font-size: 22px;
    color: #cedbed;

    @media (max-width: 1270px) {
        font-size: 18px;
    }     
    
    @media (max-width: 950px) {
        padding-bottom: 25px;
    } 

    @media (max-width: 450px) {
        font-size: 16px;
    }
`;

export const RiskProduction = styled.div`
    width: 2px;
    background-color: #3250b4;
`;

export const ItemProduction = styled.div`
    background-color: #2c57cf;
    border-radius: 19px;
    height: 135px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 34px;

    @media (max-width: 1270px) {
        padding-left: 17px;
        height: 95px;
    } 
`;

export const ItemProduction2 = styled.div`
    background-color: #2c57cf;
    margin-top: 20px;
    border-radius: 19px;
    height: 135px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 34px;

    @media (max-width: 1270px) {
        padding-left: 17px;
        height: 95px;
        margin-top: 9px;
    } 

    @media (max-width: 450px) {
        padding-left: 13px;
        height: 95px;
    }
`;

export const TextProduction3 = styled.div`
    font-weight: bold;
    font-size: 22px;
    text-transform: uppercase;

    @media (max-width: 1270px) {
       font-size: 17px;
    } 

    @media (max-width: 450px) {
        font-size: 13.6px;
    }
`;

export const TextProduction4 = styled.div`
    font-weight: 300;
    font-size: 22px;
    text-transform: uppercase;

    @media (max-width: 1270px) {
       font-size: 17px;
    } 

    @media (max-width: 450px) {
        font-size: 15px;
    }
`;

export const TextProduction5 = styled.div`
    font-weight: 500;
    font-size: 22px;

    @media (max-width: 1270px) {
       font-size: 17px;
    } 

    @media (max-width: 950px) {
        font-size: 15px;
    }    
    
    @media (max-width: 450px) {
        font-size: 14px;
    }
`;
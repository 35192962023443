import styled from 'styled-components';
import ImgBgItemContato from '../../Images/BgItemContato.jpg';

export const BgContact = styled.section`
  padding-top: 90px;

@media (max-width: 1040px) {
  padding-top: 0px;
}

@media (max-width: 950px) {
  display: none;
}
`;

export const OutBgResp = styled.div`
    display: none;
    @media (max-width: 950px) {
        display: block;
    }
`;

export const TextProduction1 = styled.div`
    font-size: 75px;
    font-weight: 800;
    text-transform: uppercase;
    padding-bottom: 7vw;
    padding-top: 9vw;
    display: none;

    @media (max-width: 950px) {
        display: block;
        font-size: 40px;
        padding-bottom: 30px;
        padding-top: 0px;
        padding-top: 0px;
        text-align: center;
        width: 100%;
    } 

    @media (max-width: 550px) {
        font-size: 30px;
    } 
`;

export const OutContact = styled.div`
    display: flex;
    justify-content: space-between;
    width: 82%;
    margin: 0 auto;
    margin-top: -32vw;
    padding-bottom: 3vw;

    @media (max-width: 1250px) {
        padding-bottom: 0vw;
    }        
    
    @media (max-width: 950px) {
        flex-wrap: wrap;
        padding-top: 0vw;
        margin-top: -30vw;
    }      
    
    @media (max-width: 400px) {
        width: 88%;
    }    
`;

export const LeftContact = styled.div`
    width: 48%;

    @media (max-width: 950px) {
        width: 100%;
    }   
`;

export const LeftContact2 = styled.div`
    width: 48%;

    @media (max-width: 950px) {
        width: 100%;
        padding-top: 25px;
    }   
`;

export const TextContact2 = styled.div`
    font-weight: bold;
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 25px;
`;

export const BaixoForm = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    align-items: center;

    @media (max-width: 1220px) {
        flex-direction: column;
        align-items: unset;
    }   
`;

export const OutImgRobo = styled.div`
    @media (max-width: 1250px) {
        width: 48%;
    }   

    @media (max-width: 950px) {
        width: auto;
    }       
    
    @media (max-width: 500px) {
        width: 225px;
    }   
`;

export const RiskContact = styled.div`
    width: 2px;
    background-color: #304fa7;
`;

export const OutItemCorp = styled.div`
    background-image: url(${ImgBgItemContato});
    background-size: cover;
    height: 180px;
    background-position: center;
    display: flex;
    align-items: center;
    border-radius: 21px;
    cursor: pointer;

    @media (max-width: 1350px) {
        height: 150px;
    }        
    
    @media (max-width: 550px) {
        height: 110px;
    }    
`;

export const OutTextItemCorp = styled.div`
    width: 330px;
    padding-left: 45px;
    margin-top: -12px;

    @media (max-width: 1350px) {
       width: 270px;
       padding-left: 30px;
    }
    
    @media (max-width: 550px) {
        width: 195px;
        padding-left: 20px;
    }  
`;

export const TextItemCorp = styled.div`
    font-weight: 300;
    font-size: 55px;
    text-transform: uppercase;

    span {
        font-weight: bold;
    }

    @media (max-width: 1350px) {
        font-size: 45px;
    }      
    
    @media (max-width: 550px) {
        font-size: 32px;
    }   
`;

export const ButtonItemCorp = styled.div`
    color: #000;
    font-size: 18px;
    font-weight: bold;
    background-color: #fff;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;

    @media (max-width: 550px) {
        font-size: 13px;
        height: 35px;
    }  
`;

export const OutItemContato = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const ItemContato = styled.div`
    width: 44%;
    margin-top: 20px;

    @media (max-width: 1350px) {
        width: 100%;
    }     
    
    @media (max-width: 1250px) {
        margin-top: 10px;
    }     
    
    @media (max-width: 950px) {
        width: 305px;
        margin-top: 20px;
    }     
    
    @media (max-width: 780px) {
        width: 100%;
    } 
`;

export const TextItemContato1 = styled.div`
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 20px;
`;

export const TextItemContato2 = styled.div`
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
`;

export const TextItemContato3 = styled.div`
    font-size: 14px;
    font-weight: 500;

    @media (max-width: 480px) {
        font-size: 13px;
    } 
`;
import styled from 'styled-components';
import BgContent from '../../Images/BgContent.png';

export const BackgroundContent = styled.section`
    /* background-color: ${({ theme }) => theme.color.secondary}; */
`;

export const OutIframe = styled.div`
    width: 80%;
    margin: 0 auto;
    padding-bottom: 100px;

    @media (max-width: 1100px) {
        padding-bottom: 35px;
    }
`;

export const OutCarrousel = styled.div`
   background-image: url(${BgContent});
   background-size: cover;
   background-position: center;
   width: 100%;
   padding-bottom: 180px;

   @media (max-width: 700px) {
       display: none;
    }
`;

export const OutCarrouselResp = styled.div`
   background-image: url(${BgContent});
   background-size: cover;
   background-position: center;
   width: 100%;
   padding-bottom: 180px;
   display: none;

   @media (max-width: 700px) {
       display: block;
    }
`;

/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';


import ImgCalls2Hover from '../../Images/ImgCalls2Hover.jpg';
import * as S from './estilos';

function Calls(props: any) {
  const history = useHistory();

  const MandaFotos = () => {
    history.push('/Galeria');
  };  
  
  const MandaVideos = () => {
    history.push('/Videos');
  };  
  
  const MandaFas = () => {
    history.push('/Fas');
  };  
  
  const MandaImprensa = () => {
    history.push('/Imprensa');
  };

  return (
    <S.OutCalls>
      <S.ItemCalls onClick={() => MandaFotos()} className='ItemCalls1'>
        <S.TextItemCalls1>Fotos</S.TextItemCalls1>
      </S.ItemCalls> 

      <S.ItemCalls onClick={() => MandaVideos()} className='ItemCalls2'>
        <S.TextItemCalls1>Vídeos</S.TextItemCalls1>
      </S.ItemCalls>  

      <S.ItemCalls onClick={() => MandaFas()} className='ItemCalls3'>
        <S.TextItemCalls1>Fãs</S.TextItemCalls1>
      </S.ItemCalls>     

      <S.ItemCalls onClick={() => MandaImprensa()} className='ItemCalls4'>
        <S.TextItemCalls1>Imprensa</S.TextItemCalls1>
      </S.ItemCalls>
    </S.OutCalls>
  );
}

export default Calls;

import styled from 'styled-components';
import ImgCalls3 from '../../Images/ImgCalls3.png';

export const OutFollow = styled.section`
    width: 910px;
    margin: 0 auto;
    margin-bottom: -13vw;
    position: relative;
    z-index: 2;
    margin-top: 180px;

    @media (max-width: 980px) {
        width: 710px;
        margin-top: 95px;
    }

    @media (max-width: 760px) {
        width: 90%;
    }    
    
    @media (max-width: 550px) {
        margin-top: 55px;
        margin-bottom: -146px;
    }
`;

export const TextFollow1 = styled.div`
    font-weight: 800;
    font-size: 40px;
    text-transform: uppercase;

    @media (max-width: 550px) {
        font-size: 32px;
    }
`;

export const OutContentFollow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 65px;

    @media (max-width: 760px) {
        flex-wrap: wrap;
        margin-top: 5px;
    }
`;

export const LeftContentFollow = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 530px;
    justify-content: space-between;

    @media (max-width: 980px) {
        width: 430px;
    }

    @media (max-width: 760px) {
        width: 100%;
    }
`;

export const ItemContentFollow = styled.div`
    width: 250px;
    height: 55px;
    background-color: #dcdcdc;
    display: flex;
    align-items: center;
    border-radius: 39px;
    cursor: pointer;

    @media (max-width: 980px) {
        width: 210px;
    }   
    
    @media (max-width: 760px) {
        width: 47%;
        margin-top: 20px;
    }    
    
    @media (max-width: 550px) {
        height: 45px;
    }
`;

export const OutItemContentFollow = styled.div`
    display: flex;
    width: 165px;
    justify-content: space-between;
    padding-left: 32px;
    padding-top: 0px;
    align-items: center;

    @media (max-width: 980px) {
        width: 145px;
    }   
    
    @media (max-width: 760px) {
        width: auto;
        margin: 0 auto;
        padding-left: 0px;
    }
`;

export const OutImgIconFollow = styled.div`
    padding-top: 7px;
`;

export const TextFollow2 = styled.div`
    font-size: 18px;
    font-weight: 800;
    color: #446fe7;

    @media (max-width: 760px) {
        padding-left: 15px;
    }    
    
    @media (max-width: 550px) {
        padding-left: 7px;
        font-size: 15px;
    }
`;

export const RiskFollow = styled.div`
    background-color: #132451;
    width: 2px;
    height: 250px;
    margin-top: -40px;

    @media (max-width: 760px) {
        display: none;
    }
`;

export const RightFollow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 165px;

    @media (max-width: 760px) {
        width: 100%;
        flex-direction: row;
    }
`;

export const OutImgIconFollow2 = styled.div`
    cursor: pointer;

    @media (max-width: 760px) {
        width: 47%;
        margin-top: 20px;
    }
`;

export const OutImgFollow = styled.div`

`;

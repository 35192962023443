import styled from 'styled-components';


export const OutBgResp = styled.div`
  display: none;
  @media (max-width: 950px) {
    display: block;
  }
`;
export const TextProduction1 = styled.div`
  font-size: 75px;
  font-weight: 800;
  text-transform: uppercase;
  padding-bottom: 7vw;
  padding-top: 9vw;
  display: none;

  @media (max-width: 950px) {
    display: block;
    font-size: 40px;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-top: 0px;
    text-align: center;
  }

  @media (max-width: 550px) {
    font-size: 30px;
  }
`;

export const BgContentDisco = styled.section`
  padding-top: 90px;

  @media (max-width: 1040px) {
    padding-top: 0px;
  }

  @media (max-width: 950px) {
    display: none;
  }
`;

export const OutContentDisco = styled.div`
  width: 83%;
  margin: 0 auto;
  margin-top: -36vw;
  margin-bottom: -6vw;

  @media (max-width: 1500px) {
    margin-top: -42vw;
  }

  @media (max-width: 950px) {
    margin-bottom: 0vw;
    margin-top: -29vw;
  }
`;

export const TopDisco = styled.div`
  display: flex;
  padding-left: 7%;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
`;

export const TextDisco1 = styled.div`
  font-size: 35px;
  font-weight: bold;
  text-transform: uppercase;

  @media (max-width: 500px) {
    font-size: 26px;
  }
`;

export const RiscoDisco1 = styled.div`
  background-color: #304fa7;
  width: 3px;
  height: 35px;
  margin-left: 15px;
  margin-right: 15px;

  @media (max-width: 500px) {
    margin-left: 10px;
    margin-right: 10px;
    width: 2px;
  }
`;

export const TextDisco2 = styled.div`
  font-size: 22px;
  font-weight: bold;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const RiscoDisco2 = styled.div`
  background-color: #304fa7;
  width: 100%;
  height: 2px;
  position: relative;
  margin-bottom: 35px;
`;

export const RiscoDisco3 = styled.div`
  background-color: #304fa7;
  width: 100%;
  height: 2px;
  position: relative;
  margin-top: 35px;
`;

export const OutIframeDisco = styled.div`
  width: 86%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;

  @media (max-width: 950px) {
    width: 90%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const ItemDisco = styled.div`
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const LeftItem = styled.div`
  width: 40%;

  @media (max-width: 950px) {
    width: 100%;
    margin-bottom: 20px;
  }

  @media (max-width: 561px) {
    margin-bottom: 0px;
  }
`;

export const CenterItem = styled.div`
  width: 30%;

  @media (max-width: 950px) {
    width: 50%;
  }

  @media (max-width: 450px) {
    width: 55%;
  }
`;

export const RightItem = styled.div`
  width: 14%;
  display: flex;
  flex-direction: column;

  @media (max-width: 950px) {
    width: 30%;
  }

  @media (max-width: 450px) {
    width: 33%;
  }
`;

export const OutImgRightItem = styled.div`
  cursor: pointer;
  margin-top: 0.9vw;

  @media (max-width: 950px) {
    margin-top: 2vw;
  }
`;

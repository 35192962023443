import styled from 'styled-components';

export const BgVideo = styled.section`
    padding-top: 90px;

    @media (max-width: 1040px) {
    padding-top: 0px;
    }

    @media (max-width: 950px) {
    display: none;
    }
`;

export const OutBgResp = styled.div`
    display: none;
    @media (max-width: 950px) {
        display: block;
    }
`;

export const TextProduction1 = styled.div`
    font-size: 75px;
    font-weight: 800;
    text-transform: uppercase;
    padding-bottom: 7vw;
    padding-top: 9vw;
    display: none;

    @media (max-width: 950px) {
        display: block;
        font-size: 40px;
        padding-bottom: 30px;
        padding-top: 0px;
        padding-top: 0px;
        text-align: center;
    } 

    @media (max-width: 550px) {
        font-size: 30px;
    } 
`;

export const OutVideo = styled.div`
    width: 82%;
    margin: 0 auto;
    margin-top: -37vw;

    @media (max-width: 950px) {
        margin-top: -46vw;
    } 
`;

export const TopVideo = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const OutItemsTopVideo = styled.div`
    display: flex;

    @media (max-width: 620px) {
        width: 100%;
    } 
`;

export const ItemTopVideo = styled.div`
    width: 170px;
    border: 2px solid #2d4ba1;
    border-top-right-radius: 27px;
    border-top-left-radius: 27px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 620px) {
        width: 33%;
        height: 45px;
    } 
`;

export const TextTopVideo = styled.div`
    font-weight: bold;
    font-size: 22px;
    color: #6890ff;

    @media (max-width: 620px) {
        font-size: 17px;
    }    
    
    @media (max-width: 410px) {
        font-size: 15px;
    } 
`;

export const RiskTopVideo = styled.div`
    height: 2px;
    background-color: #2d4ba1;
    width: 100%;

    @media (max-width: 620px) {
        display: none;
    } 
`;

export const ContentVideo = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;

    @media (max-width: 1250px) {
        margin-bottom: 30px;
    } 

    @media (max-width: 500px) {
         margin-bottom: 15px;
    } 
`;

export const ItemVideo = styled.div`
    margin-top: 50px;
    height: 13vw;
    background-position: center center;
    background-size: cover;
    width: 100%;

    @media (max-width: 1250px) {
        height: 16vw;
        margin-top: 20px;
    } 

    @media (max-width: 950px) {
        height: 22vw;
        margin-top: 00px;
    }    
    
    @media (max-width: 650px) {
        height: 32vw;
    }    
    
    @media (max-width: 500px) {
        height: 61vw;
        margin-top: 20px;
    } 
`;

export const OutItemVideo = styled.div`
    width: 23%;
    cursor: pointer;

    @media (max-width: 1250px) {
        width: 24%;
        margin-top: 30px;
    } 

    @media (max-width: 950px) {
        width: 32%;
    }    
    
    @media (max-width: 650px) {
        width: 48%;
    }    
    
    @media (max-width: 500px) {
        width: 100%;
        margin-top: 15px;
    } 
`;

export const OutImgVideo = styled.div`
    :hover {
        border: 5px solid #2d4ba1;
        cursor: pointer;
    }
`;

export const LowItemVideo = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
`;

export const RiskImgVideo = styled.div`

`;

export const RiskItemVideo = styled.div`
    width: 2px;
    background-color: #2f4483;
`;  

export const TextItemVideo = styled.div`
    width: 93%;
    color: #cedbed;
    font-size: 15px;
    font-weight: 300;

    @media (max-width: 1250px) {
       font-size: 14px;
    } 
`;

export const LowVideo = styled.div`

`;

export const RiskLowVideo = styled.div`
    width: 100%;
    height: 2px;
    background-color: #1c3271;
`;

export const OutMoreVideos = styled.div`
    width: 350px;
    margin: 0 auto;
    border: 2px solid #1c3271;
    border-top: 0px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 32px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 600px) {
        width: 200px;
        height: 45px;
    } 
`;

export const TextMoreVideos = styled.div`
    font-weight: 500;
    color: #5990ed;
    font-size: 18px;
    text-transform: uppercase;
    padding-right: 15px;
    margin-top: -2px;

    @media (max-width: 600px) {
        padding-right: 5px;
    } 
`;

export const OutIconVideos = styled.div`

`;

export const XGallery = styled.div`
    position: absolute;
    right: 49px;
    top: 25px;
    font-weight: bold;
    font-size: 32px;
    cursor: pointer;
    z-index: 1001;

    @media (max-width: 600px) {
        right: 15px;
        font-size: 26px;
    } 
`;
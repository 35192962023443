import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as S from './estilos';
import useData from '../../Utils/useData';

import IconPartner1 from '../../Images/IconPartner1.png';
import IconPartner2 from '../../Images/IconPartner2.png';
import IconPartner3 from '../../Images/IconPartner3.png';
import IconPartner4 from '../../Images/IconPartner4.png';
import IconPartner5 from '../../Images/IconPartner5.png';
import IconPartner6 from '../../Images/IconPartner6.png';
import IconLab from '../../Images/IconLab.png';

function Footer() {
  const [data, setData] = useState<any>([]);
  const loadData = () => {
    useData()
      .getData('parceiro.php')
      .then((response: any) => {
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  const MandaLab = () => {
    window.open('http://lab3.tv/', '_blank');
  };  

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <S.OutPartner>
        <S.TopPartner>
          <S.RiskPartner />
          <S.TextPartner>parceiros</S.TextPartner>
          <S.RiskPartner />
        </S.TopPartner>
        <S.ContentPartner>
          <S.OutItensContentPartner>
            {data &&
              data.map((data: any, index: React.Key | null | undefined) => (
                <S.OutImgPartner onClick={() => (data.c_url !== '' ? window.open(data.c_url) : null)} key={index}>
                  <img
                    src={'https://daniel.art.br/upload/parceiro/' + data.id_tb_parceiro + '.' + data.c_extensao1}
                    width='100px'
                  />
                </S.OutImgPartner>
              ))}
          </S.OutItensContentPartner>
        </S.ContentPartner>
      </S.OutPartner>

      <S.OutLab onClick={() => MandaLab()}>
        <S.OutImgLab>
          <img src={IconLab} />
        </S.OutImgLab>
      </S.OutLab>
    </>
  );
}

export default Footer;

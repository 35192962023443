import styled from 'styled-components';
import ImgBgNews from '../../Images/BgNews.jpg';

export const BgNews = styled.section`
   background-image: url(${ImgBgNews});
   background-size: cover;
   background-position: center;
   height: 54vw;

   @media (max-width: 950px) {
        background-image: unset;
        margin-bottom: 0vw;
        margin-top: -34vw;
    } 
`;

export const OutBgResp = styled.div`
    display: none;
    @media (max-width: 950px) {
        display: block;
    }
`;

export const TextFas1 = styled.div`
    font-size: 75px;
    font-weight: 800;
    text-transform: uppercase;
    padding-bottom: 7vw;
    padding-top: 9vw;
    display: none;

    @media (max-width: 950px) {
        display: block;
        font-size: 40px;
        padding-bottom: 30px;
        padding-top: 0px;
        padding-top: 0px;
        text-align: center;
    } 

    @media (max-width: 550px) {
        font-size: 30px;
    } 
`;

export const OutNews = styled.div`
    width: 83%;
    margin: 0 auto;
    margin-top: -36vw;
    padding-bottom: 1vw;

    img {
        width: auto;
        margin-top: 35px;
        margin-bottom: 35px;
    }

    @media (max-width: 950px) {
        margin-top: -53vw;    
    } 

    @media (max-width: 550px) {
        img {
            max-width: 100%;
        }
    } 
`;

export const TextNews1 = styled.div`
    font-size: 27px;
    font-weight: bold;

    @media (max-width: 950px) {
       display: none;
    } 
`;

export const TextNews2 = styled.div`
    font-size: 21px;
    font-weight: 400;

    @media (max-width: 550px) {
        font-size: 18px; 
    } 
`;

export const ContentNews = styled.div`
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TopInternalNews = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const DataNews = styled.div`
    font-weight: bold;
    color: #d69c49;
    font-size: 25px;
    width: auto;

    @media (max-width: 550px) {
        font-size: 23px;
    } 
`;

export const ButtonVoltar = styled.div`
    font-weight: bold;
    text-transform: uppercase;
    font-size: 21px;
    text-decoration: underline;
    cursor: pointer;
`;

export const TitleNews = styled.div`
    font-weight: bold;
    font-size: 24px;
    border-bottom: 2px solid #d69c49;
    padding-bottom: 21px;
    margin-top: 20px;
    width: 100%;

    @media (max-width: 550px) {
        font-size: 19px;
    } 
`;

export const OutImageNews = styled.div`
    margin: 0 auto;
    margin-top: 35px;
    margin-bottom: 35px;
`;

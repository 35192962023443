import styled from 'styled-components';


export const OutBanner = styled.section`
  padding-top: 90px;

  @media (max-width: 1040px) {
    padding-top: 0px;
  }
`;

export const OutContentBanner = styled.div`
  width: 1130px;
  margin: 0 auto;
  margin-top: -17vw;
  padding-bottom: 75px;

  @media (max-width: 1150px) {
    width: 820px;
  }  
  
  @media (max-width: 850px) {
    width: 85%;
    margin-top: -62vw;
  }    
  
  @media (max-width: 700px) {
    padding-bottom: 35px;
  }   
  
  @media (max-width: 500px) {
    margin-top: -82vw;
  }  
`;  

export const TextBanner1 = styled.div`
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  
  @media (max-width: 500px) {
    font-size: 4.8vw;
    margin: 0 auto;
  }  

`;

export const OutLowBanner = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    align-items: center;

    @media (max-width: 850px) {
      flex-direction: column;
      margin-top: 0px;
    }     
`;

export const LeftLowBanner = styled.div`
    text-align: right;

    @media (max-width: 850px) {
      text-align: center;
      margin-top: 15px;     
      margin-top: -5px;
    }    
`;

export const OutImageOpus = styled.div`
  @media (max-width: 1150px) {
    width: 170px;
  }  
  
  @media (max-width: 850px) {
    display: none;
  }
`;

export const OutImageOpusResp = styled.div`
  display: none;

  @media (max-width: 850px) {
    display: block;
    width: 370px;
    margin: 0 auto;
    margin-top: 20px;
  }

  @media (max-width: 500px) {
    width: 205px;
    margin-top: 5px;
  }
`;

export const RightLowBanner = styled.div`
    @media (max-width: 850px) {
      text-align: center;
      margin-top: 25px;
    }       
    
    @media (max-width: 500px) {
      margin-top: 5px;
    }    
`;

export const TextBanner2 = styled.div`
  font-weight: bold;
  font-size: 20px;

  @media (max-width: 850px) {
    font-size: 29px;
  }    
  
  @media (max-width: 500px) {
    font-size: 20px;
  }  
`;

export const TextBanner3 = styled.div`
  font-weight: 300;
  font-size: 18px;

  @media (max-width: 1150px) {
    font-size: 15px;
  }       
  
  @media (max-width: 850px) {
    font-size: 20px;
  }   
  
  @media (max-width: 500px) {
    font-size: 3.7vw;
  }     
`;

import styled from 'styled-components';
import ImgBgApp from '../../Images/BgApp.jpg';

export const BgApp = styled.section`
   background-image: url(${ImgBgApp});
   background-size: cover;
   background-position: center;
   width: 100%;
   padding-bottom: 180px;

   @media (max-width: 750px) {
        display: none;
    }
`;

export const OutApp = styled.div`
    width: 1170px;
    margin: 0 auto;
    padding-top: 250px;
    padding-bottom: 630px;

    @media (max-width: 1250px) {
        width: 920px;
        padding-top: 180px;
        padding-bottom: 510px;
    }    
    
    @media (max-width: 1020px) {
        width: 700px;
        padding-top: 90px;
        padding-bottom: 315px;
    }
`;

export const LeftApp = styled.div`
    width: 50%;

    @media (max-width: 1020px) {
        width: 55%;
    }
`;

export const TextApp1 = styled.div`
    font-weight: 800;
    font-size: 40px;
    text-transform: uppercase;
`;

export const ContentApp = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    @media (max-width: 1250px) {
        margin-top: 25px;
    }
`;

export const ItemApp = styled.div`
    width: 250px;
    cursor: pointer;

    @media (max-width: 1250px) {
        width: 220px;
    }

    @media (max-width: 1020px) {
        width: 185px;
    }

`;

export const OutImgApp = styled.div`

`;

export const ButtonApp = styled.div`
    width: 100%;
    height: 55px;
    background-color: #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    cursor: pointer;
    margin-top: 45px;
`;

export const TextButtonApp = styled.div`
    font-weight: 500;
    font-size: 18px;
    color: #446fe7;

    span {
        font-weight: 800;
    }

    @media (max-width: 1020px) {
        font-size: 15px;
    }
`;

export const OutAppResp = styled.div`
    display: none;
    margin-top: 25px;

    @media (max-width: 750px) {
        display: block;
    }
`;

export const OutLinkApp1 = styled.div`
    width: 49vw;
    height: 26vw;
    position: absolute;
    margin-top: -77vw;
    margin-left: 4vw;
    cursor: pointer;
`;

export const OutLinkApp2 = styled.div`
    width: 49vw;
    height: 26vw;
    position: absolute;
    margin-top: -47vw;
    margin-left: 4vw;
    cursor: pointer;
`;
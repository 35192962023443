/* eslint-disable react/prop-types */
import { url } from "inspector";
import React, { useEffect, useState } from "react";

import IconFollow1 from "../../Images/IconFollow1.png";
import IconFollow2 from "../../Images/IconFollow2.png";
import IconFollow3 from "../../Images/IconFollow3.png";
import IconFollow4 from "../../Images/IconFollow4.png";
import IconSpot from "../../Images/IconSpot.png";
import IconDeezer from "../../Images/IconDeezer.png";
import BgFollow from "../../Images/BgFollow.jpg";

import * as S from "./estilos";

function Follow(props: any) {
  const MandaInsta = () => {
    window.open("https://www.instagram.com/cantordaniel/", "_blank");
  };

  const MandaYoutube = () => {
    window.open("https://www.youtube.com/user/DanielOficial", "_blank");
  };

  const MandaTwitter = () => {
    window.open("https://twitter.com/cantordaniel", "_blank");
  };

  const MandaFace = () => {
    window.open("https://www.facebook.com/cantorDaniel", "_blank");
  };

  const MandaSpot = () => {
    window.open("https://open.spotify.com/artist/2wwWukJBsCikjlj7vlbQkM?si=oypBmx0ITYWpzsHKYHFLyw&nd=1", "_blank");
  };

  const MandaDeezer = () => {
    window.open("https://www.deezer.com/br/artist/64998", "_blank");
  };

  return (
    <>
      <S.OutFollow>
        <S.TextFollow1>Siga o daniel</S.TextFollow1>
        <S.OutContentFollow>
          <S.LeftContentFollow>
            <S.ItemContentFollow onClick={() => MandaInsta()}>
              <S.OutItemContentFollow>
                <S.OutImgIconFollow>
                  <img src={IconFollow1} />
                </S.OutImgIconFollow>
                <S.TextFollow2>Instagram</S.TextFollow2>
              </S.OutItemContentFollow>
            </S.ItemContentFollow>
            <S.ItemContentFollow onClick={() => MandaYoutube()}>
              <S.OutItemContentFollow>
                <S.OutImgIconFollow>
                  <img src={IconFollow2} />
                </S.OutImgIconFollow>
                <S.TextFollow2>Youtube</S.TextFollow2>
              </S.OutItemContentFollow>
            </S.ItemContentFollow>
            {/* <S.ItemContentFollow onClick={() => MandaTwitter()}>
            <S.OutItemContentFollow>
              <S.OutImgIconFollow>
                <img src={IconFollow3} />
              </S.OutImgIconFollow>
              <S.TextFollow2>Twitter</S.TextFollow2>
            </S.OutItemContentFollow>
          </S.ItemContentFollow>           */}
            <S.ItemContentFollow onClick={() => MandaFace()}>
              <S.OutItemContentFollow>
                <S.OutImgIconFollow>
                  <img src={IconFollow4} />
                </S.OutImgIconFollow>
                <S.TextFollow2>Facebook</S.TextFollow2>
              </S.OutItemContentFollow>
            </S.ItemContentFollow>
          </S.LeftContentFollow>
          <S.RiskFollow />
          <S.RightFollow>
            <S.OutImgIconFollow2 onClick={() => MandaSpot()}>
              <img src={IconSpot} />
            </S.OutImgIconFollow2>
            <S.OutImgIconFollow2 onClick={() => MandaDeezer()}>
              <img src={IconDeezer} />
            </S.OutImgIconFollow2>
          </S.RightFollow>
        </S.OutContentFollow>
      </S.OutFollow>

      <S.OutImgFollow>
        <img src={BgFollow} />
      </S.OutImgFollow>
    </>
  );
}

export default Follow;

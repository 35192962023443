import React from 'react';
import * as S from './estilos';

// import { Schedule } from '..';
// IconDownload
import BgProducaoResp from '../../Images/BgProducaoResp.jpg';
import ImgBgProducao from '../../Images/BgProducao.jpg';

const ProductionInside = () => {
  return (
    <>  
      <S.OutBgResp>
        <img src={BgProducaoResp} />
      </S.OutBgResp>

      <S.BgProduction>
       <img src={ImgBgProducao} />
      </S.BgProduction>
      
      <S.OutProduction>
          <S.TextProduction1>Produção</S.TextProduction1>
          <S.LeftProduction>
            <S.TextProduction2>
              Para informações gerais sobre nossa produção, como hospedagem, necessidades de sonorização, iluminação, mapas, palco, necessidades de camarins, 
              transporte, segurança, infra-estrutura e etc, entrar em contato diretamente com nossa produção, através dos contatos:
            </S.TextProduction2>
          </S.LeftProduction>
          <S.RiskProduction />
          <S.LeftProduction>
            <S.ItemProduction>
              <S.TextProduction3>Produção geral / General producer</S.TextProduction3>
              <S.TextProduction4>Casagrande</S.TextProduction4>
              <S.TextProduction5>casagrande@danielpromocoes.com.br</S.TextProduction5>
            </S.ItemProduction>            
            <S.ItemProduction2>
              <S.TextProduction3>Produtor Técnico</S.TextProduction3>
              <S.TextProduction4>Joel Araujo</S.TextProduction4>
              <S.TextProduction5>joel@danielpromocoes.com.br</S.TextProduction5>
            </S.ItemProduction2>
          </S.LeftProduction>
        </S.OutProduction>

    </>
  );
};

export default ProductionInside;

import styled from 'styled-components';
import ImgCalls3 from '../../Images/ImgCalls3.png';

export const OutCalls = styled.section`
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: -22vw;

    @media (max-width: 1600px) {
        margin-top: -26vw;
    }

    @media (max-width: 1400px) {
        margin-top: -34vw;
        width: 90%;
    }    
    
    @media (max-width: 1250px) {
        margin-top: -32vw;
    }    
    
    @media (max-width: 1020px) {
        margin-top: -25vw;
    }   
    
    @media (max-width: 850px) {
        margin-top: -34vw;
    }    
    
    @media (max-width: 750px) {
        margin-top: -15vw;
        flex-wrap: wrap;
    }
`;

export const ItemCalls = styled.div`
    width: 24%;
    cursor: pointer;

    @media (max-width: 750px) {
        width: 48%;
        margin-top: 30px;
    }
`;

export const TextItemCalls1 = styled.div`
    padding-top: 23vw;
    font-weight: 800;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 3vw;

    @media (max-width: 1600px) {
        font-size: 24px;
    }

    @media (max-width: 750px) {
        padding-top: 55vw;
    }   
    
    @media (max-width: 600px) {
        font-size: 19px;
    }    
    
    @media (max-width: 420px) {
        font-size: 15px
    }
`;


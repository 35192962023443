import React, { useEffect, useRef, useState } from 'react';
import * as S from './estilos';
import { useHistory, withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

// IconDownload
import BgNewsResp from '../../Images/BgNewsResp.jpg';
import IconDownload from '../../Images/IconDownload.png';
import IconSchedule6 from '../../Images/IconSchedule6.png';
import ImgVideo from '../../Images/ImgVideo.jpg';
import useData from '../../Utils/useData';
import ImgBgNews from '../../Images/BgNews.jpg';

const NewsInside = () => {
  const history = useHistory();
  const [data, setData] = useState<any>([]);
  const isVisibleNext = useRef<any>(true);
  const category = useRef<any>('');
  const page = useRef<any>(1);

  const [titulo, setTitulo] = useState("");
  const [dataNoticia, setDataNoticia] = useState("");
  const [texto, setTexto] = useState("");
  const [img, setImg] = useState();
  const [id, setId] = useState();

  const FechaNoticia = () => {
    setTitulo("");
    setDataNoticia("");
    setTexto("");
  };

  const loadData = () => {
    useData()
      .getData(`noticias.php?page=${page.current}`)
      .then((response: any) => {
        console.log(response);

        if (response.length < 8) {
          isVisibleNext.current = false;
        }
        if (page.current == 1) {
          setData(response);
        } else {
          setData((data: any) => [...data, ...response]);
        }
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    category.current = '';
    loadData();
  }, []);

  return (
    <>
      <S.OutBgResp>
        <img src={BgNewsResp} />
      </S.OutBgResp>

      <S.BgNews>
        <img src={ImgBgNews} />
      </S.BgNews>
      
      <S.OutNews>
        <S.TextFas1>News</S.TextFas1>
        <S.TextNews1>Últimas Notícias</S.TextNews1>

        {titulo === "" &&
        <>
          <S.ContentNews>
            {data &&
              data.map((data: any, index: React.Key | null | undefined) => (
                <S.ItemNews key={index} onClick={() => {setId(data.id_tb_noticia); setTitulo(data.c_titulo); setImg(data.c_extensao1); setDataNoticia(data.c_data.substring(6, 8) + "/" + data.c_data.substring(4, 6) + "/" + data.c_data.substring(0, 4)); setTexto(data.c_texto)}}>
                  <S.TextNews2>
                    {data.c_data.substring(6, 8)}.{data.c_data.substring(4, 6)}.{data.c_data.substring(0, 4)}
                  </S.TextNews2>

                  <S.TextNews3>{data.c_titulo}</S.TextNews3>
                </S.ItemNews>
              ))}
          </S.ContentNews>
          <S.ButtonSchedule2
            onClick={() => {
              page.current = page.current + 1;
              loadData();
            }}>
            <S.TextButtonSchedule2>Mais Notícias</S.TextButtonSchedule2>
            <S.OutImgIconSchedule3>
              <img src={IconSchedule6} />
            </S.OutImgIconSchedule3>
          </S.ButtonSchedule2>
        </>
        }

        {titulo !== "" &&
          <S.OutNews2>
            <S.ContentNews2>
            <S.TopInternalNews>
              <S.DataNews>{dataNoticia}</S.DataNews>
              <S.ButtonVoltar onClick={() => FechaNoticia()}>Voltar</S.ButtonVoltar>
            </S.TopInternalNews>
            
            <S.TitleNews>{titulo}</S.TitleNews>
            <img src={"https://daniel.art.br/upload/noticia/" + id + "." + img } />
            <S.TextNewsInternal>
              {ReactHtmlParser(texto)}
            </S.TextNewsInternal>
          </S.ContentNews2>
        </ S.OutNews2>
        }

      </S.OutNews>
    </>
  );
};

export default NewsInside;

import React, { useState, useEffect } from "react";
import * as S from "./estilos";

import Load from "../Load";

import ReCAPTCHA from "react-google-recaptcha";
import ReactHtmlParser from "react-html-parser";

import { Alert } from "../atoms";
import useData from "../../Utils/useData/index";

import BgContatoResp2 from "../../Images/BgContatoResp2.jpg";
import ImgBgContato from "../../Images/BgContato.jpg";
import ImgRobo from "../../Images/ImgRobo.jpg";

const ContactInside = () => {
  const [data, setData] = useState<any>([]);
  const [load, setLoad] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [captcha, setCaptcha] = useState("");

  const loadData = () => {
    useData()
      .getData("contato.php")
      .then((response: any) => {
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  function onChange(value: React.SetStateAction<string>) {
    setCaptcha("xxx");
  }

  const MandaCorporativo = () => {
    window.open("https://danielcorp.com.br/", "_blank");
  };

  const phoneMask = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{4})/, "$1-$2");
  };

  const clearFields = () => {
    setNome("");
    setCelular("");
    setEmail("");
    setMensagem("");
  };

  const onSubmit = () => {
    let validaMenssages = [];
    validaMenssages.push(<div style={{ marginBottom: "10px" }}>Atenção, segue abaixo campos obrigatórios:</div>);
    let valida = false;
    if (nome === "" || nome === undefined) {
      validaMenssages.push(<div key={"Nome"}>- Nome</div>);
      valida = true;
    }
    if (celular === "" || celular === undefined) {
      validaMenssages.push(<div key={"Telefone"}>- Telefone</div>);
      valida = true;
    }
    // alert(captcha);
    // if (captcha === '' || captcha === undefined) {
    //   validaMenssages.push(<div key={'Telefone'}>- Verificação de robô</div>);
    //   valida = true;
    // }
    if (email === "" || email === undefined) {
      validaMenssages.push(<div key={"E-mail"}>- E-mail</div>);
      valida = true;
    } else {
      if (
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email,
        )
      ) {
        null;
      } else {
        validaMenssages.push(<div key={"E-mail"}>- E-mail (inválido)</div>);
        valida = true;
      }
    }
    if (mensagem === "" || mensagem === undefined) {
      validaMenssages.push(<div key={"Mensagem"}>- Mensagem</div>);
      valida = true;
    }
    if (valida) {
      Alert(<div>{validaMenssages}</div>);
    } else {
      setLoad(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        Nome: nome,
        Celular: celular,
        Email: email,
        Mensagem: mensagem,
      });

      validaMenssages = [];

      fetch("https://daniel.art.br/app_site/envia_contato.php", {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      })
        .then((response) => response.text())
        .then((response: any) => {
          // console.log(response);
          setLoad(false);
          Alert(
            <>
              <div>Formulário de contato enviado com sucesso!</div>
              <div>Em breve entraremos em contato.</div>
            </>,
          );
          clearFields();
          validaMenssages = [];
          // setNome('');
          // setEmail('');
          // setCelular('');
          // setMensagem('');
          setLoad(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <>
      {load == true && <Load status={true} />}

      <S.OutBgResp>
        <img src={BgContatoResp2} />
      </S.OutBgResp>

      <S.BgContact>
        <img src={ImgBgContato} />
      </S.BgContact>

      <S.OutContact>
        <S.TextProduction1>Contato</S.TextProduction1>
        <S.LeftContact>
          <S.TextContact2>Envie Sua Mensagem</S.TextContact2>
          <form className="FormContato">
            <input
              className="InputForm"
              type="text"
              onChange={(e) => setNome(e.target.value)}
              value={nome}
              name="c_nome"
              id="c_nome"
              placeholder="Nome"
            />
            <input
              className="InputForm"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              name="c_email"
              id="c_email"
              placeholder="E-mail"
            />
            <input
              className="InputForm"
              onChange={(e) => setCelular(e.target.value)}
              value={phoneMask(celular)}
              maxLength={15}
              name="c_telefone"
              id="c_telefone"
              placeholder="Telefone"
            />
            <textarea
              className="InputTextArea"
              onChange={(e) => setMensagem(e.target.value)}
              value={mensagem}
              name="c_comentario"
              id="c_comentario"
              placeholder="Comentário"
            />
            <S.BaixoForm>
              <ReCAPTCHA sitekey="6LdGCFYlAAAAAFCCPfh-wSxmsUYXbWTSYD0XvTn-" onChange={() => onChange("xx")} />
              <button onClick={() => onSubmit()} type="button" className="BotaoEnvia">
                Enviar
              </button>
            </S.BaixoForm>
          </form>
        </S.LeftContact>
        <S.RiskContact />
        <S.LeftContact2>
          <S.TextContact2>Contratantes</S.TextContact2>
          <S.OutItemCorp onClick={() => MandaCorporativo()}>
            <S.OutTextItemCorp>
              <S.TextItemCorp>
                Daniel<span>Corp</span>
              </S.TextItemCorp>
              <S.ButtonItemCorp>Acessar site Corporativo</S.ButtonItemCorp>
            </S.OutTextItemCorp>
          </S.OutItemCorp>
          {data &&
            data.map((data: any, index: React.Key | null | undefined) => (
              <S.OutItemContato key={index}>{ReactHtmlParser(data.c_contato)}</S.OutItemContato>
            ))}
          {/* 
          <S.OutItemContato>
            <S.ItemContato>
              <S.TextItemContato1>Vendas de show</S.TextItemContato1>
              <S.TextItemContato3>leticia.berbel@opusentretenimento.com</S.TextItemContato3>
              <S.TextItemContato3>+55 11 99931-1051</S.TextItemContato3>
            </S.ItemContato>
            
              <S.ItemContato>
                <S.TextItemContato1>ENVIO DE COMPOSIÇÕES (MP3):</S.TextItemContato1>
                <S.TextItemContato3>ENVIO DE COMPOSIÇÕES</S.TextItemContato3>
                <S.TextItemContato3>composicoes@danielpromocoes.com.br</S.TextItemContato3>
              </S.ItemContato>
            
            <S.ItemContato>
              <S.TextItemContato1>CENTRAL DE FÃS:</S.TextItemContato1>
              <S.TextItemContato2>MARLENE PANIGUEL</S.TextItemContato2>
              <S.TextItemContato3>atendimento@danielpromocoes.com.br</S.TextItemContato3>
              <S.TextItemContato3>contato@danielpromocoes.com.br</S.TextItemContato3>
              <S.TextItemContato3>(14) 3653.9898 (08hs às 15hs)</S.TextItemContato3>
            </S.ItemContato>
            <S.ItemContato>
              <S.TextItemContato1>RÁDIOS:</S.TextItemContato1>
              <S.TextItemContato2>ANDRÉ CAMARGO</S.TextItemContato2>
              <S.TextItemContato3>radios@danielpromocoes.com.br</S.TextItemContato3>
            </S.ItemContato>
            <S.ItemContato>
              <S.TextItemContato1>CAMPANHAS PUBLICITÁRIAS</S.TextItemContato1>
              <S.TextItemContato2>ALESSANDRA MESCHINI</S.TextItemContato2>
              <S.TextItemContato3>alessandra@danielpromocoes.com.br</S.TextItemContato3>
              <S.TextItemContato3>(11) 97412.4751</S.TextItemContato3>
            </S.ItemContato>
            <S.ItemContato>
              <S.TextItemContato1>IMPRENSA</S.TextItemContato1>
              <S.TextItemContato3>COSTABILE SALZANO</S.TextItemContato3>
              <S.TextItemContato3 style={{ fontSize: "12px" }}>costabile.salzano@opusentretenimento.com</S.TextItemContato3>
            </S.ItemContato>
            <S.ItemContato>
              <S.TextItemContato1>DANIEL PROM. ARTÍSTICAS LTDA</S.TextItemContato1>
              <S.TextItemContato3>Av Mario Pinotti, 689, Centro</S.TextItemContato3>
              <S.TextItemContato3>Brotas/SP – CEP 17380-000</S.TextItemContato3>
            </S.ItemContato>
            <S.ItemContato>
              <S.TextItemContato1>ASSESSORIA DE TV:</S.TextItemContato1>
              <S.TextItemContato2>GLÓRIA BICHO</S.TextItemContato2>
              <S.TextItemContato3>gloria.bicho@danielpromocoes.com.br</S.TextItemContato3>
              <S.TextItemContato3>(21) 98211.9273</S.TextItemContato3>
            </S.ItemContato>
          </S.OutItemContato>
          */}
        </S.LeftContact2>
      </S.OutContact>
    </>
  );
};

export default ContactInside;

import styled from 'styled-components';

export const BgFas = styled.section`
  padding-top: 90px;

    @media (max-width: 1040px) {
    padding-top: 0px;
    }

    @media (max-width: 950px) {
    display: none;
    }
`;

export const OutBgResp = styled.div`
    display: none;
    @media (max-width: 950px) {
        display: block;
    }
`;

export const OutFas = styled.div`
    width: 83%;
    margin: 0 auto;
    padding-top: 0vw;
    padding-bottom: 2vw;
    margin-top: -35vw;

    @media (max-width: 1460px) {
        padding-bottom: 0vw;
    }     
    
    @media (max-width: 1210px) {
        padding-bottom: 0vw;
    } 

    @media (max-width: 1060px) {
        width: 90%;
    } 

    @media (max-width: 950px) {
        padding-top: 0vw;
        margin-top: -23vw;
    }    
    
    @media (max-width: 550px) {
        width: 85%;
    }
`;  

export const TextFas1 = styled.div`
    font-size: 75px;
    font-weight: 800;
    text-transform: uppercase;
    padding-bottom: 7vw;
    padding-top: 9vw;
    display: none;

    @media (max-width: 950px) {
        display: block;
        font-size: 40px;
        padding-bottom: 30px;
        padding-top: 0px;
        padding-top: 0px;
        text-align: center;
    } 

    @media (max-width: 550px) {
        font-size: 30px;
    } 
`;

export const ContentFas = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 950px) {
        flex-wrap: wrap;
    } 
`;

export const LeftFas = styled.div`
    width: 40%;

    @media (max-width: 950px) {
        width: 100%;
    } 
`;

export const TextFas2 = styled.div`
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;

    @media (max-width: 1020px) {
        font-size: 20px;
    }  
`;

export const TextFas3 = styled.div`
    font-size: 20px;
    font-weight: 300;
    color: #cedbed;
    margin-top: 30px;

    @media (max-width: 1350px) {
        font-size: 18px;
        margin-top: 13px;
    }    
    
    @media (max-width: 1020px) {
        font-size: 17px;
        margin-top: 10px;
    } 
`;

export const RiskFas = styled.div`
    width: 2px;
    background-color: #304fa7;

    @media (max-width: 950px) {
        width: 100%;
        height: 2px;
        margin-top: 35px;
        margin-bottom: 35px;
    } 
`;

export const RightFas = styled.div`
    width: 51%;

    @media (max-width: 950px) {
        width: 100%;
    } 
`;

export const ContentBlueFas = styled.div`
    background-color: #2c57cf;
    width: 80%;
    padding: 20px 40px;
    border-radius: 19px;
    margin-top: 35px;
      
    @media (max-width: 1020px) {
        margin-top: 18px;
        padding: 10px 30px;
    } 

    @media (max-width: 950px) {
        width: 89%;
    } 

    @media (max-width: 550px) {
        margin-top: 18px;
        padding: 10px 15px;
        width: 91%;
    } 
`;

export const TextFas4 = styled.div`
    font-size: 30px;
    font-weight: 600;

    @media (max-width: 1350px) {
        font-size: 22px;
    } 
`;

export const TextFas5 = styled.a`
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    text-decoration: unset;

    @media (max-width: 1350px) {
        font-size: 17px;
    }     
    
    @media (max-width: 550px) {
        font-size: 13.8px;
    } 
`;

export const TextFas6 = styled.a`
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    text-decoration: unset;

    @media (max-width: 1020px) {
        font-size: 17px;
    } 

    @media (max-width: 550px) {
        font-size: 15px;
    } 
`;

export const TextFas7 = styled.div`
    font-size: 22px;
    font-weight: 300;
    text-transform: uppercase;
`;


